<template>
  <div class="log-table">
    <a-table
      :loading="loading"
      :columns="columns"
      :data-source="data"
      :row-key="(record, index) => index"
      :pagination="false"
    >
    </a-table>
    <base-pagination
      :current-page="pagination.page"
      :page-size="pagination.size"
      :total="pagination.total"
      @onChange="handlePaginationChange"
      @onShowSizeChange="handlePaginationChange"
    />
  </div>
</template>

<script>
const columns = [
  {
    title: '操作',
    dataIndex: 'type',
    key: 'type',
    width: 100,
  },
  {
    title: '具体描述',
    dataIndex: 'action',
    key: 'action',
    width: 100
  },
  {
    title: '操作时间',
    dataIndex: 'ctime',
    key: 'ctime',
    width: 100
  },
  {
    title: '操作人',
    dataIndex: 'operator',
    key: 'operator',
    width: 100
  }
]

export default {
  name: 'DataTable',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    data: {
      type: Array,
      default () {
        return []
      }
    },
    pagination: {
      type: Object,
      default () {
        return {
          current: 1,
          pageSize: 10,
          total: 0
        }
      }
    }
  },
  data () {
    return {
      columns
    }
  },
  methods: {
    // 处理分页改变
    handlePaginationChange(page, size) {
      this.$emit('pagination-change', page, size)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>