<template>
  <a-form-model
    ref="form"
    :model="form"
    :label-col="{span: 4}"
    :wrapper-col="{span: 20}"
  >
    <div class="product-list">
      <template v-for="(item, index) in form.list">
        <div
          class="product-item product-item--live"
          v-if="item.productType === '直播'"
          :key="index"
        >
          <div class="product-item__cover">
            <base-cover
              :src="item.productCover"
              :width="180"
              :height="320"
            />
          </div>
          <div class="product-item__content">
            <div class="author-info">
              <span>【直播】</span>
              <span class="author-name">
                <i class="icon icon-douyin" v-if="item.platform === '抖音'"></i>
                <i class="icon icon-kuaishou" v-if="item.platform === '快手'"></i>
                {{ item.authorName || '--' }}
              </span>
            </div>
            <div class="product-info">
              <div class="product-info__title">
                {{ item.productTitle || '--' }}
              </div>
              <div class="product-info__time mt-10">
                <span>开播时间：{{ item.publishTime || '--' }}</span>
                <span>{{ item.statusName || '--' }}</span>  
              </div>
              <div class="product-info__target">
                <div class="target">
                  <div class="target__hd">关键指标</div>
                  <div class="target__bd">
                    <div class="data-item">
                      <div class="data-item__label">时长</div>
                      <div class="data-item__value">
                        {{ item.liveTime }}
                      </div>
                    </div>
                    <div class="data-item">
                      <div class="data-item__label">观众数</div>
                      <div class="data-item__value">
                        {{ item.liveWatchUser }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="target">
                  <div class="target__hd">对比上场</div>
                  <div class="target__bd">
                    <div class="data-item">
                      <div class="data-item__value">
                        {{ item.liveTimeCompare }}
                      </div>
                    </div>
                    <div class="data-item">
                      <div class="data-item__value">
                        {{ item.liveWatchUserCompare }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <a-row class="mt-10" :gutter="20">
              <a-col :span="8">
                <a-form-model-item
                  label="作品类型"
                  :prop="'list.' + index + '.contentType'"
                  :rules="[
                    {required: true, message: '请选择作品类型', trigger: 'change'}
                  ]"
                >
                  <a-select
                    v-model="item.contentType"
                    :disabled="disabled"
                  >
                    <a-select-option
                      v-for="liveTypeItem in liveTypeList"
                      :key="liveTypeItem.value"
                      :value="liveTypeItem.value"
                    >
                      {{ liveTypeItem.label }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="8">
                <a-form-model-item
                  :prop="'list.' + index + '.isConfirmed'"
                  :rules="[
                    {required: true, message: '请勾选导师已确认', trigger: 'change'}
                  ]"
                >
                  <a-checkbox-group
                    v-model="item.isConfirmed"
                    :disabled="disabled"
                  >
                    <a-checkbox :value="1">
                      导师已确认
                    </a-checkbox>
                  </a-checkbox-group>
                </a-form-model-item>
              </a-col>
            </a-row>
          </div>
        </div>

        <div
          class="product-item product-item--aweme"
          v-if="item.productType === '短视频'"
          :key="index"
        >
          <div class="product-item__cover">
            <base-cover
              :src="item.productCover"
              :width="180"
              :height="320"
            />
          </div>
          <div class="product-item__content">
            <div class="author-info">
              <span>【短视频】</span>
              <span class="author-name">
                <i class="icon icon-douyin" v-if="item.platform === '抖音'"></i>
                <i class="icon icon-kuaishou" v-if="item.platform === '快手'"></i>
                {{ item.authorName || '--' }}
              </span>
            </div>
            <div class="product-info">
              <div class="product-info__title">
                {{ item.productTitle || '--' }}
              </div>
              <div class="product-info__time mt-10">
                <span>发布时间：{{ item.publishTime || '--' }}</span>
                <span>{{ item.statusName || '--' }}</span>  
              </div>
              <div class="product-info__target">
                <div class="target">
                  <div class="target__hd">
                    关键指标
                  </div>
                  <div class="target__bd">
                    <div class="data-item">
                      <div class="data-item__label">播放量</div>
                      <div class="data-item__value">
                        {{ item.playVolume }}
                      </div>
                    </div>
                    <div class="data-item">
                      <div class="data-item__label">点赞</div>
                      <div class="data-item__value">
                        {{ item.diggCount }}
                      </div>
                    </div>
                    <div class="data-item">
                      <div class="data-item__label">评论</div>
                      <div class="data-item__value">
                        {{ item.commentCount }}
                      </div>
                    </div>
                    <div class="data-item">
                      <div class="data-item__label">转发</div>
                      <div class="data-item__value">
                        {{ item.shareCount }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <a-row class="mt-10" :gutter="20">
              <a-col :span="8">
                <a-form-model-item
                  label="作品类型"
                  :prop="'list.' + index + '.contentType'"
                  :rules="[
                    {required: true, message: '请选择作品类型', trigger: 'change'}
                  ]"
                >
                  <a-select
                    v-model="item.contentType"
                    :disabled="disabled"
                  >
                    <a-select-option
                      v-for="awemeTypeItem in awemeTypeList"
                      :key="awemeTypeItem.value"
                      :value="awemeTypeItem.value"
                    >
                      {{ awemeTypeItem.label }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="8">
                <a-form-model-item
                  :prop="'list.' + index + '.isConfirmed'"
                  :rules="[
                    {required: true, message: '请勾选导师已确认', trigger: 'change'}
                  ]"
                >
                  <a-checkbox-group
                    v-model="item.isConfirmed"
                    :disabled="disabled"
                  >
                    <a-checkbox :value="1">
                      导师已确认
                    </a-checkbox>
                  </a-checkbox-group>
                </a-form-model-item>
              </a-col>
            </a-row>
          </div>
        </div>
      </template>
    </div>
  </a-form-model>
</template>

<script>
import BaseCover from '@/components/BaseCover'

export default {
  name: 'ProductForm',
  components: {
    BaseCover
  },
  props: {
    form: {
      type: Object,
      default () { return {}}
    },
    liveTypeList: {
      type: Array,
      default () { return [] }
    },
    awemeTypeList: {
      type: Array,
      default () { return [] }
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    validate () {
      return new Promise((resolve, reject) => {
        this.$refs.form.validate(valid => {
          if (valid) {
            resolve(true)
          } else {
            reject(false)
          }
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.mt-10 {
  margin: 10px 0 0;
}

.product-item {
  display: flex;
  align-items: flex-start;
  margin: 0 0 20px;

  &__cover {
    width: 180px;
    height: 320px;
  }

  &__content {
    flex: 1;
    padding: 0 0 0 20px;
  }
}

.author-info {
  display: flex;
  align-items: center;
  font-weight: bold;

  & .author-name {
    display: flex;
    align-items: center;
    margin: 0 0 0 10px;

    & .icon {
      display: inline-block;
      margin: 0 10px 0 0;
      width: 20px;
      height: 20px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      overflow: hidden;

      &-douyin {
        background-image: url("../../../../../../assets/icon/icon_douyin.png");
      }

      &-kuaishou {
        background-image: url("../../../../../../assets/icon/icon_kuaishou.png");
      }

      &-dongchedi {
        background-image: url("../../../../../../assets/icon/icon_dongchedi.png");
      }
    }
  }
}

.product-info {
  margin: 10px 0 0;
  padding: 10px;
  background: #ccc;
  border-radius: 10px;
}

.target {
  display: flex;
  align-items: center;

  &__hd {
    padding: 20px;
  }

  &__bd {
    display: flex;
    align-items: center;
    padding: 20px;
  }
}

.data-item {
  margin: 0 20px 0 0;
  min-width: 100px;
}
</style>
