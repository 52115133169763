<template>
  <div class="base-cover" :style="coverStyle">
    <div class="base-cover__img" v-if="isShowImg">
      <img :src="src" alt="">
    </div>
    <div class="base-cover__text" v-else>
      <span>{{ text }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseCover',
  props: {
    src: {
      type: String,
      default: ''
    },
    width: {
      type: Number,
      default: 124
    },
    height: {
      type: Number,
      default: 165
    },
    text: {
      type: String,
      default: '暂无封面'
    }
  },
  data () {
    return {
      isShowImg: false
    }
  },
  mounted () {
    this.loadImg()
  },
  computed: {
    coverStyle () {
      return `width: ${this.width}px;height: ${this.height}px;`
    }
  },
  watch: {
    src () {
      this.loadImg()
    }
  },
  methods: {
    loadImg () {
      if (this.src) {
        let img = new Image()

        img.src = this.src
        img.onload = () => {
          this.isShowImg = true
        }
        img.onerror = () => {
          this.isShowImg = false  
        }
      } else {
        this.isShowImg = false
      }
    }
  }
}
</script>

<style lang="less" scoped>
.base-cover {
  cursor: default;

  &__img {
    width: 100%;
    height: 100%;
    
    & img {
      width: 100%;
      height: 100%;
    }
  }

  &__text {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: #FFF;
    font-size: 14px;
    background: #ccc;
  }
}
</style>
