<template>
  <a-form-model
    class="form"
    ref="form"
    :model="form"
    :rules="rules"
    :label-col="{span: 4}"
    :wrapper-col="{span: 20}"
  >
    <a-row :gutter="60">
      <a-col :span="8">
        <a-form-model-item label="店端状态" prop="storeStatus">
          <a-select
            v-model="form.storeStatus"
            :disabled="disabled"
            style="width: 100%;"
          >
            <a-select-option
              v-for="item in storeStatusList"
              :key="item.value"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-col>
      <a-col :span="8">
        <a-form-model-item label="主播状态" prop="authorStatus">
          <a-select
            v-model="form.authorStatus"
            :disabled="disabled"
            style="width: 100%;"
          >
            <a-select-option
              v-for="item in authorStatusList"
              :key="item.value"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-col>
      <a-col :span="16">
        <a-form-model-item
          prop="remarkType"
          :wrapper-col="{offset: 2, span: 22}"
        >
          <a-radio-group
            v-model="form.remarkType"
            :disabled="disabled"
          >
            <a-radio :value="2">
              异常
            </a-radio>
            <a-radio :value="1">
              其他
            </a-radio>
          </a-radio-group>
        </a-form-model-item>
      </a-col>
      <a-col :span="16">
        <a-form-model-item
          label="备注"
          prop="remark"
          :label-col="{span: 2}"
          :wrapper-col="{span: 22}"
        >
          <a-textarea
            v-model="form.remark"
            :disabled="disabled"
            :rows="4"
            placeholder="请按需选填，100字以内。"
          />
          <p class="tips">此信息提交后，会更新到【主播档案】作为历史跟踪信息</p>
        </a-form-model-item>
      </a-col>
    </a-row>
  </a-form-model>
</template>

<script>
export default {
  name: 'StoreForm',
  props: {
    form: {
      type: Object,
      default () {
        return {}
      }
    },
    storeStatusList: {
      type: Array,
      default () { return [] }
    },
    authorStatusList: {
      type: Array,
      default () { return [] }
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      rules: {
        remark: [
          { max: 100, message: '最多可输入100字', trigger: 'blur' }
        ]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.tips {
  margin-bottom: 0;
  font-size: 12px;
  line-height: 20px;
}
</style>
