<template>
  <a-card class="page-container">
    <a-spin :spinning="loading">
      <h1>
        <span>{{ userInfo.principalName || '--' }}</span>
        <span>{{ userInfo.areaName || '--' }}</span>
        <span>{{ userInfo.dealerName || '--' }}</span>
      </h1>
      
      <a-divider />
      <product-form
        ref="productForm"
        :form="productForm"
        :live-type-list="liveTypeList"
        :aweme-type-list="awemeTypeList"
        :disabled="mode === 'check'"
      />

      <a-divider />
      <div class="form-wrap2">
        <store-form
          :form="storeForm"
          :storeStatusList="storeStatusList"
          :authorStatusList="authorStatusList"
          :disabled="mode === 'check'"
        />
      </div>

      <a-divider />
      <div class="form-wrap2">
        <store-config-form
          :form="storeConfigForm"
          :disabled="mode === 'check'"
        />
      </div>

      <a-divider v-if="mode === 'check'" />
      <div class="log-wrap" v-if="mode === 'check'">
        <div class="log-title">操作日志</div>
        <log-table
          :loading="logLoading"
          :data="logList"
          :pagination="logPagination"
          @pagination-change="handleLogPaginationChange"
        />
      </div>

      <div class="btn-wrap" v-if="mode === 'edit'">
        <a-button
          type="primary"
          :loading="submitLoading"
          @click="handleSubmit"
        >
          提交转下一学员
        </a-button>
      </div>
    </a-spin>
  </a-card>
</template>

<script>
import ProductForm from './components/ProductForm'
import StoreForm from './components/StoreForm'
import StoreConfigForm from './components/StoreConfigForm'
import LogTable from './components/LogTable'
import {
  storeStatusList,
  authorStatusList,
  awemeTypeList,
  liveTypeList
} from '@/utils/fhlUserConst'
import activityApi from '@/api/activity'

export default {
  name: 'actContentDetailOperAssi',
  components: {
    ProductForm,
    StoreForm,
    StoreConfigForm,
    LogTable
  },
  data () {
    return {
      mode: 'edit',
      loading: false,
      userInfo: {},
      productForm: {
        list: []
      },
      liveTypeList,
      awemeTypeList,
      storeStatusList,
      authorStatusList,
      storeForm: {
        storeStatus: '',
        authorStatus: '',
        remarkType: '',
        remark: ''
      },
      storeConfigForm: {
        storeConfig: '',
        incentivePolicy: ''
      },
      submitLoading: false,
      logLoading: false,
      logList: [],
      logPagination: {
        page: 1,
        size: 10,
        total: 0
      }
    }
  },
  created () {
    this.mode = Number(this.$route.query.submitStatus) === 1 ? 'edit' : 'check'
    this.fetchProductDetail()

    if (this.mode === 'check') {
      this.fetchProductDetailLog()
    }
  },
  methods: {
    // 获取作品详情
    async fetchProductDetail () {
      this.loading = true
      try {
        const params = {
          activityId: this.$route.query.activityId,
          dealerId: this.$route.query.dealerId,
          submitStatus: this.$route.query.submitStatus
        }
        const { code, data, message } = await activityApi.fetchProductDetail(params)

        if (code === 200) {
          this.userInfo = data.userInfo

          if (this.mode === 'edit') {
            this.productForm.list = data.productList.map(item => {
              return { ...item, contentType: '', isConfirmed: [] }
            })
          } else {
            this.productForm.list = data.productList.map(item => {
              return { ...item, isConfirmed: [1] }
            })

            this.storeForm = {
              storeStatus: data.userInfo.storeStatus ? data.userInfo.storeStatus : '',
              authorStatus: data.userInfo.authorStatus ? data.userInfo.authorStatus : '',
              remarkType: data.userInfo.remarkType ? data.userInfo.remarkType : '',
              remark: data.userInfo.remark ? data.userInfo.remark : ''
            }

            this.storeConfigForm = {
              storeConfig: data.userInfo.storeConfig ? data.userInfo.storeConfig : '',
              incentivePolicy: data.userInfo.incentivePolicy ? data.userInfo.incentivePolicy : ''  
            }
          }
        } else {
          this.$message.error(message)
        }

        this.loading = false
      } catch (e) {
        console.log(e)
        this.loading = false
      }
    },
    // 处理提交
    async handleSubmit () {
      try {
        const valid = await this.$refs.productForm.validate()

        valid && this.submitProuctDetail()
      } catch (e) {
        console.log(e)
        this.$message.error('请确认信息是否已填好')
      }
    },
    // 提交作品详情
    async submitProuctDetail () {
      this.submitLoading = true
      try {
        let productList = this.productForm.list.filter(item => item.isConfirmed.length > 0)
        productList = productList.map(item => {
          const { platform, productType, productId, contentType } = item
          return { platform, productType, productId, contentType }  
        })

        let userInfo = {
          storeStatus: this.storeForm.storeStatus,
          authorStatus: this.storeForm.authorStatus,
          storeConfig: this.storeConfigForm.storeConfig,
          incentivePolicy: this.storeConfigForm.incentivePolicy
        }

        if (this.storeForm.remarkType) {
          userInfo.remarkType = this.storeForm.remarkType
          userInfo.remark = this.storeForm.remark
        }

        const params = {
          activityId: this.$route.query.activityId,
          dealerId: this.$route.query.dealerId,
          productList,
          userInfo
        }
        const { code, data, message } = await activityApi.submitProuctDetail(params)

        if (code === 200) {
          if (data) {
            this.$message.success('提交成功，请继续下一个')
            this.userInfo = {}
            this.productForm.list = []
            this.$nextTick(() => {
              this.userInfo = data.userInfo
              this.productForm.list = data.productList.map(item => {
                return { ...item, contentType: '', isConfirmed: [] }
              })
              this.storeForm = {
                storeStatus: '',
                authorStatus: '',
                remarkType: '',
                remark: ''
              }
              this.storeConfigForm = {
                storeConfig: '',
                incentivePolicy: ''  
              }
            })
          } else {
            this.$message.success('提交成功')
            this.$router.push({
              name: 'actManage:contentListOperAssi',
              query: {
                activityId: this.$route.query.activityId  
              }
            })
          }
        } else {
          this.$message.error(message)
        }

        this.submitLoading = false
      } catch (e) {
        console.log(e)
        this.submitLoading = false
      }
    },
    // 处理分页改变
    handleLogPaginationChange (page, size) {
      this.logPagination.page = page
      this.logPagination.size = size
      this.fetchProductDetailLog()
    },
    // 获取作品详情操作日志
    async fetchProductDetailLog () {
      this.logLoading = true
      try {
        const params = {
          dealerId: this.$route.query.dealerId,
          ...this.logPagination
        }
        const { code, data, message } = await activityApi.fetchProductDetailLog(params)

        if (code === 200) {
          this.logList = data.list
          this.logPagination.total = data.total
          
        } else {
          this.$message.error(message)
        }

        this.logLoading = false
      } catch (e) {
        console.log(e)
        this.logLoading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  padding: 0 !important;
}

h1 {
  span {
    margin: 0 20px 0 0;
  }
}

.form-wrap2 {
  margin: 20px 0 0;
  padding: 20px;
  background: #ccc;
  border-radius: 10px;
}

.log-wrap {
  .log-title {
    margin: 0 0 10px;
    font-size: 16px;
    font-weight: bold;  
  }
}

.btn-wrap {
  margin: 40px 0 0;
  text-align: center;
}
</style>
