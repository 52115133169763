export const storeStatusList = [
  { label: '正常营业', value: 1 },
  { label: '疫情闭店', value: 2 },
  { label: '闭店', value: 3 }
]

export const storeStatusFilter = (val) => {
  const index = storeStatusList.findIndex(item => item.value === val)

  if (index > -1) {
    return storeStatusList[index].label
  } else {
    return '-'
  }
}

export const authorStatusList = [
  { label: '正常开播', value: 1 },
  { label: '不定期居家隔离', value: 2 },
  { label: '方舱隔离', value: 3 },
  { label: '回店后较长时间居家隔离', value: 4 },
  { label: '居家隔离', value: 5 },
  { label: '主播回店不定期隔离', value: 6 }
]

export const authorStatusFilter = (val) => {
  const index = authorStatusList.findIndex(item => item.value === val)

  if (index > -1) {
    return authorStatusList[index].label
  } else {
    return '-'
  }
}

export const awemeTypeList = [
  { label: '专业说车', value: 1 },
  { label: '文案流车型卡点', value: 2 },
  { label: '纯车型显示卡点', value: 3 },
  { label: '舞蹈类', value: 4 },
  { label: '剧情段子', value: 5 }
]

export const awemeTypeFilter = (val) => {
  const index = awemeTypeList.findIndex(item => item.value === val)

  if (index > -1) {
    return awemeTypeList[index].label
  } else {
    return '-'
  }
}

export const liveTypeList = [
  { label: '正常直播', value: 1 },
  { label: '试驾直播', value: 2 },
  { label: '多人直播', value: 3 }
]

export const liveTypeFilter = (val) => {
  const index = liveTypeList.findIndex(item => item.value === val)

  if (index > -1) {
    return liveTypeList[index].label
  } else {
    return '-'
  }
}

export const potentialityList = [
  { label: '普通店', value: 1 },
  { label: '机会店', value: 2 },
  { label: '高潜店', value: 3 },
  { label: '预警店', value: 4 },
  { label: '偏科店强视频', value: 5 },
  { label: '偏科店强直播', value: 6 }
]

export const potentialityFilter = (val) => {
  const index = potentialityList.findIndex(item => item.value === val)

  if (index > -1) {
    return potentialityList[index].label
  } else {
    return ''
  }
}

// 企微好友状态列表
export const wechatStatusList = [
  { label: '已加', value: 1 },
  { label: '已删', value: 2 },
  { label: '未加', value: 3 }
]

// 企微好友状态过滤器
export const wechatStatusFilter = (val) => {
  const index = wechatStatusList.findIndex(item => item.value === val)

  return index > -1 ? wechatStatusList[index].label : '-'
}
